.chapter-heading {
  letter-spacing: 1.3px !important;
  text-align: center;
  font-weight: 900;
  padding: 1rem;
}
.chapter-img {
  padding: 0 1rem !important;
  width: 100% !important;
  margin-bottom: 2rem;
}
.chapter-desc {
  text-align: justify;
  padding: 0 2rem;
  font-weight: 600;
}
.quote {
  font-weight: 900;
  font-style: italic;
}
.list {
  padding: 1rem 2rem;
}
