@import url(https://fonts.googleapis.com/css2?family=Sora&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #2b2b2b;
}

::-webkit-scrollbar-thumb {
  background: rgba(83, 78, 83, 0.959);
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(83, 78, 83, 0.959);
  border-radius: 12px;
}

* {
  font-family: 'Sora', sans-serif, 'Montserrat';
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.logout-icon {
  cursor: pointer;
  width: 28px;
  height: 28px;
  padding-left: 6px;
  color: white;
}

.dot {
  height: 80px;
  width: 80px;
  margin-top: 35px;
  border: 3px solid #f1f1f1;
  border-radius: 50%;
  display: inline-block;
}

#mission {
  border-radius: 15px;
}

#mission:hover .dot {
  border: 3px solid #46adbe;
  transition: 0.5s ease-in-out;
}

#mission .dot {
  transition: 0.5s ease-in-out;
}

.event:hover {
  background-color: #46adbe;
  transition: 0.3s ease-in-out;
}

.event:hover .txt {
  color: white;
  transition: 0.3s ease-in-out;
}

.event:hover .btn {
  background-color: white;
  color: black;
  transition: 0.3s ease-in-out;
}

.event:hover .icon {
  color: white;
  transition: 0.3s ease-in-out;
}

.event {
  transition: 0.3s ease-in-out;
}

.btn {
  margin-top: 5px;
}

@media only screen and (min-width: 320px) and (max-width: 560px) {
  .hosted {
    display: none;
  }

  .btn {
    margin-top: 10px;
  }
}

.teamicon {
  color: #46adbe;
  transition: 0.3s ease-in-out;
}

.circle {
  height: 150px;
  width: 150px;
  margin-top: 20px;
  background-color: #46adbe;
  border-radius: 50%;
  display: inline-block;
  transition: 0.3s ease-in-out;
}

.team:hover {
  background-color: #46adbe;
}

.team:hover .teamicon {
  color: white;
  transition: 0.3s ease-in-out;
}

.team:hover .circle {
  background-color: white;
  transform: translateY(-15px);
  transition: 0.3s ease-in-out;
}

#community {
  background-image: url(/static/media/team.67e5ad2e.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 95%;
  height: 85vh;
}

#communityimg {
  display: none;
}

@media only screen and (min-width: 320px) and (max-width: 1025px) {
  .hosted {
    display: none;
  }

  .btn {
    margin-top: 10px;
  }

  #community {
    background: none;
    height: auto;
  }

  #communityimg {
    display: inline;
  }
}

.bounce {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce-1;
  animation-timing-function: linear;
  margin: auto;
}
@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

.socialmedia {
  height: 40px;
  width: 40px;
  background: #46adbe;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

#coursebtn {
  background: #003049;
}

.texthovering {
  color: white;
  opacity: 0.8;
}

.texthovering:hover {
  color: #46adbe;
  opacity: 1;
  font-weight: bold;
}

@media only screen and (min-width: 320px) and (max-width: 770px) {
  .desktopview {
    height: 80vh;
  }
}

.dayscard:hover {
  color: #46adbe;
}

.css-1ozmk1d {
  min-width: 5rem !important;
}

.bold-text {
  font-weight: bold;
}

.link {
  color: #46adbe;
}

.evt-btn {
  color: black;
  cursor: pointer;
}

.lead-btn {
  color: black;
  margin-left: 6px;
  cursor: pointer;
}

.login-page {
  width: 100% !important;
  height: 100vh !important;
  display: flex;
  padding: 20px;
}
.login-img {
  width: 350px;
  height: 350px;
  margin: auto;
}
.cw-logo {
  width: 90px;
  height: 100px;
  margin: 0 auto;
  padding-top: 1rem;
}
.login-box {
  box-shadow: 0 0 8px 0 #3b3b3b;
  border-radius: 10px;
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 350px;
  /* min-width: 300px;
  min-height: 200px; */
  height: auto;
  justify-content: center;
}
.community {
  margin-top: 0.8rem;
  font-weight: 700;
  text-align: center;
  color: #3b3b3b;
}
.google {
  width: 30px;
  height: 30px;
}
.login-btn {
  margin: 2rem auto;
  padding: 24px !important;
  color: #3b3b3b;
}

.chapter-heading {
  letter-spacing: 1.3px !important;
  text-align: center;
  font-weight: 900;
  padding: 1rem;
}
.chapter-img {
  padding: 0 1rem !important;
  width: 100% !important;
  margin-bottom: 2rem;
}
.chapter-desc {
  text-align: justify;
  padding: 0 2rem;
  font-weight: 600;
}
.quote {
  font-weight: 900;
  font-style: italic;
}
.list {
  padding: 1rem 2rem;
}

