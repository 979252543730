.evt-btn {
  color: black;
  cursor: pointer;
}

.lead-btn {
  color: black;
  margin-left: 6px;
  cursor: pointer;
}
