.login-page {
  width: 100% !important;
  height: 100vh !important;
  display: flex;
  padding: 20px;
}
.login-img {
  width: 350px;
  height: 350px;
  margin: auto;
}
.cw-logo {
  width: 90px;
  height: 100px;
  margin: 0 auto;
  padding-top: 1rem;
}
.login-box {
  box-shadow: 0 0 8px 0 #3b3b3b;
  border-radius: 10px;
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 350px;
  /* min-width: 300px;
  min-height: 200px; */
  height: auto;
  justify-content: center;
}
.community {
  margin-top: 0.8rem;
  font-weight: 700;
  text-align: center;
  color: #3b3b3b;
}
.google {
  width: 30px;
  height: 30px;
}
.login-btn {
  margin: 2rem auto;
  padding: 24px !important;
  color: #3b3b3b;
}
